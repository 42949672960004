import React from "react"

const PreLoader = () => (
  <>
    {/* <!-- ============================================--> */}
    {/* <!-- Preloader ==================================--> */}
    <div className="preloader" id="preloader">
      <div className="loader">
        <div className="line-scale-pulse-out-rapid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    {/* <!-- ============================================--> */}
    {/* <!-- End of Preloader ===========================--> */}
  </>
)

export default PreLoader
