import React from "react"
import { Link } from "gatsby";


const Footer = () => (
  <>
    {/* <!--===============================================--> */}
    {/* <!--    Footer--> */}
    {/* <!--===============================================--> */}
    <footer className="footer bg-black text-600 py-4 text-sans-serif text-center overflow-hidden" data-zanim-timeline="{}" data-zanim-trigger="scroll">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 order-lg-2">
            <a class="indicator indicator-up" data-fancyscroll="data-fancyscroll" href="#top"><span className="indicator-arrow indicator-arrow-one" data-zanim-xs='{"from":{"opacity":0,"y":15},"to":{"opacity":1,"y":-5,"scale":1},"ease":"Back.easeOut","duration":0.4,"delay":0.9}'></span><span className="indicator-arrow indicator-arrow-two" data-zanim-xs='{"from":{"opacity":0,"y":15},"to":{"opacity":1,"y":-5,"scale":1},"ease":"Back.easeOut","duration":0.4,"delay":1.05}'></span></a>
          </div>
          <div className="col-lg-4 text-lg-left mt-4 mt-lg-0">
            <p className="fs--1 text-uppercase ls font-weight-bold mb-0">Copyright &copy; {new Date().getFullYear()} WOW Labs&trade;.</p>
          </div>
          <div className="col-lg-4 text-lg-right order-lg-2 mt-2 mt-lg-0">
            <p className="fs--1 text-uppercase ls font-weight-bold mb-0">Made with<span className="text-danger fas fa-heart mx-1"></span>by &nbsp;
              <a className="text-600" href="https://www.wowlabs.co.uk/">WOW Labs</a>
            </p>
          </div>
        </div>
      </div>
    </footer>

    {/* <!--===============================================--> */}
    {/* <!--    Modal for language selection--> */}
    {/* <!--===============================================--> */}
    {/* <div className="remodal bg-black remodal-select-language" data-remodal-id="language">
      <div className="remodal-close" data-remodal-action="close"></div>
      <ul className="list-unstyled pl-0 my-0 py-4 text-sans-serif">
        <li>
          <a className="pt-1 d-block text-white font-weight-semi-bold" href="#">English</a>
        </li>
        <li>
          <a className="pt-1 d-block text-500" href="/">Français</a>
        </li>
        <li>
          <a className="pt-1 d-block text-500" href="/">عربى</a>
        </li>
        <li>
          <a className="pt-1 d-block text-500" href="/">Deutsche</a>
        </li>
      </ul>
    </div> */}
  </>
)

export default Footer
