import React from "react";
import { Link } from "gatsby";


const Navigation = () => (
  <>
    {/* <!--===============================================--> */}
    {/* <!--    Fancynav--> */}
    {/* <!--===============================================--> */}
    <nav className="fancynavbar" data-zanim-lg='{"from":{"opacity":1,"x":70},"to":{"opacity":1,"x":0},"ease":"CubicBezier","duration":0.8,"delay":0.3}' data-zanim-xs='{"from":{"opacity":1,"y":-48},"to":{"opacity":1,"y":0},"ease":"CubicBezier","duration":0.8,"delay":0.3}'
        data-zanim-trigger="scroll" data-exclusive="true">
      <div className="fancynavbar-togglerbar" data-onscroll-fade-in="data-onscroll-fade-in">
        <Link className="fancynavbar-brand" to="/">          
         <img className="fancynavbar-brand-img" src="/img/small-logo-white.png" alt="" width="40" height="40" data-zanim-lg='{"from":{"opacity":0,"x":45},"to":{"opacity":1,"x":0},"ease":"CubicBezier","duration":0.8,"delay":0.4}' data-zanim-trigger="scroll"/>
        </Link>
        <div className="fancynavbar-toggler">
          <svg className="fancynavbar-toggler-icon" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg" data-zanim-lg='{"from":{"opacity":0,"x":45},"to":{"opacity":1,"x":0},"ease":"CubicBezier","duration":0.8,"delay":0.5}' data-zanim-trigger="scroll">
            <path id="path-top" d="M20,25c0,0,22,0,30,0c16,0,18.89,40.71-.15,21.75C38.7,35.65,19.9,16.8,19.9,16.8"></path>
            <path id="path-middle" d="M20,32h30"></path>
            <path id="path-bottom" d="M19.9,46.98c0,0,18.8-18.85,29.95-29.95C68.89-1.92,66,38.78,50,38.78c-8,0-30,0-30,0"></path>
          </svg>
        </div>
        <div className="fancynavbar-addon" data-zanim-lg='{"from":{"opacity":0,"x":45},"to":{"opacity":1,"x":0},"ease":"CubicBezier","duration":0.8,"delay":0.4}' data-zanim-trigger="scroll">
          <a target="_blank" className="fancynavbar-addon-item" rel="noopener noreferrer" href="https://medium.com/d6-digital"><span className="fab fa-medium"></span></a>
          <a target="_blank" className="fancynavbar-addon-item" rel="noopener noreferrer" href="https://twitter.com/d6xdigital"><span className="fab fa-twitter"></span></a>
          <a target="_blank" className="fancynavbar-addon-item" rel="noopener noreferrer" href="https://www.facebook.com/D6xdigital/"><span className="fab fa-facebook"></span></a>
          <a target="_blank" className="fancynavbar-addon-item" rel="noopener noreferrer" href="/" data-remodal-target="language"><span className="text-sans-serif ls font-weight-black fs--1">EN</span></a>
        </div>
      </div>
      <div className="fancynavbar-collapse">
        <ul className="fancynavbar-nav">
        <li className="fancynav-item">
        <Link className="fancynav-link" to="/"><span className="fancynav-link-content">Home</span></Link>
        {/* <a className="fancynav-link" href="/" ><span className="fancynav-link-content">Home</span></a> */}
          </li>
          <li className="fancynav-item fancy-dropdown">
            <a className="fancynav-link fancy-dropdown-toggle" href="JavaScript:void(0)"><span className="fancynav-link-content">Blogs</span></a>
            <div className="fancy-dropdown-menu">
              <div className="row pb-4 pt-3">
                <div className="col-auto">
                  
                <a className="fancy-dropdown-item" target="_blank" rel="noopener noreferrer" href="https://medium.com/d6-digital" >All</a>
                  <a className="fancy-dropdown-item" target="_blank" rel="noopener noreferrer" href="https://medium.com/d6-digital/tagged/case-study  " >Case Studies</a>
                </div>
              </div>
            </div>
          </li>
          <li className="fancynav-item">
          <a className="fancynav-link" href="/contact" ><span className="fancynav-link-content">Contact</span></a>
          {/* <Link className="fancynav-link" to="/contact" state={{isJsLoaded: true}}><span className="fancynav-link-content">Contact</span></Link> */}
          </li>
        </ul>
      </div>
    </nav>
    {/* <!--===============================================--> */}
    {/* <!--    End of Fancynav--> */}
    {/* <!--===============================================--> */}
  </>
)

export default Navigation
